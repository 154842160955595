
import {defineComponent} from "vue";
import {LoadPanel} from "@/core/composite/composite";
import TaskCompleteStatus from "@/views/task/TaskCompleteStatus.vue";

export default defineComponent({
  name: "TaskCalendarName",
  components: {TaskCompleteStatus},
  props: {
    task: {},
    isPanel: {default: false}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  },
  methods: {
    onClick(id) {
      this.loadPanel(id, 'TASK')
    }
  }
})
