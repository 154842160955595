
import {defineComponent, onMounted, ref} from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import {TODAY} from "@/core/data/events";
import TaskService from "@/core/services/TaskService";
import {mapCalendarItem} from "@/core/helpers/functions";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import TaskCalendarName from "@/views/task/TaskCalendarName.vue";
import {GetSpaceId, TeamSelect} from "@/core/composite/composite";
import moment from "moment";
import UserListSelect from "@/components/common/UserListSelect.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "Monthly",
  components: {
    BaseSelect,
    UserListSelect,
    TaskCalendarName,
    FullCalendar,
  },
  setup() {
    const start = ref(moment().startOf("month").toDate())
    const end = ref(moment().endOf("month").toDate())
    const spaceId = GetSpaceId().spaceId;
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Monthly Calendar",
        [
          {link: true, router: `/` + spaceId.value + "/work/all", text: 'Work'},
          {link: false, router: '', text: 'Monthly Calendar'}
        ]
      )
    })
    const calendarOptions = ref({
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      headerToolbar: {
        left: "",
        center: "title",
        right: ""
      },
      initialDate: TODAY,
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      selectMirror: true,
      weekends: true,
      views: {
        dayGridMonth: {buttonText: "month"},
      },
      // editable: true,
      dayMaxEvents: true, // allow "more" link when too many events
      events: [],
    });

    const filter = ref({start: start.value.getTime(), end: end.value.getTime(), teamId: '', assignedId: ''})
    const loadCalendar = () => {
      TaskService.calendar(filter.value).then(res => {
        const items = mapCalendarItem(res);
        calendarOptions.value.events = mapCalendarItem(items)
      })
    }
    loadCalendar();
    return {
      ...TeamSelect(),
      spaceId,
      start,
      end,
      filter,
      loadCalendar,
      calendarOptions,
    };
  },
  methods: {
    onPrevious() {
      const calendar = this.$refs.fullCalendarRef as typeof FullCalendar
      const api = calendar.getApi()
      api.prev();
      const tempStart = moment(this.start.getTime()).add(-1, 'month')
      this.start = tempStart.toDate();
      const tempEnd = moment(this.end.getTime()).add(-1, 'month')
      this.end = tempEnd.toDate();
      this.filter.start = this.start.getTime()
      this.filter.end= this.end.getTime()
      this.loadCalendar()
    },
    onNext() {
      const calendar = this.$refs.fullCalendarRef as typeof FullCalendar
      const api = calendar.getApi()
      api.next();
      const tempStart = moment(this.start.getTime()).add(1, 'month')
      this.start = tempStart.toDate();
      const tempEnd = moment(this.end.getTime()).add(1, 'month')
      this.end = tempEnd.toDate();
      this.filter.start = this.start.getTime()
      this.filter.end= this.end.getTime()
      this.loadCalendar()
    }
  }
});
